<script>
  import cardValidator from 'card-validator'

  /**
   * A credit card input.
   */
  export default {
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      /** The input’s value. */
      value: {
        type: String,
        default: '',
      },
    },
    computed: {
      card() {
        return cardValidator.number(this.value)?.card
      },
      cardType() {
        return this.card?.niceType
      },
      lengths() {
        return this.card?.lengths ?? [13, 14, 15, 16, 17, 18, 19]
      },
      minLength() {
        return this.lengths[0]
      },
      maxLength() {
        return this.lengths.slice(-1)[0]
      },
      gaps() {
        return this.card?.gaps ?? [4, 8, 12]
      },
      mask() {
        let mask = ''
        let digitCount = 0
        this.lengths.forEach((length, index) => {
          const fromLength = index > 0 ? this.lengths[index - 1] : 0
          const toLength = length
          if (index > 0) {
            mask += '['
          }
          for (let i = 0; i < toLength - fromLength; i++) {
            if (this.gaps.includes(digitCount)) {
              mask += ' '
            }
            mask += '0'
            digitCount++
          }
          if (index > 0) {
            mask += ']'
          }
        })
        return mask
      },
      securityCodeName() {
        return this.card?.code?.name
      },
      securityCodeLength() {
        return this.card?.code?.size
      },
    },
  }
</script>

<template>
  <BaseInput
    type="tel"
    :rules="{ creditCard: true }"
    :mask="mask"
    :value-is-unmasked="true"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <!-- @slot The input’s label. Should contain text. Defaults to “Card Number”. -->
    <slot>Card Number</slot>
    <template v-slot:icons>
      <!-- @slot Optional. Icon(s) to display on the right end of the input. Should contain one or multiple `BaseIconInput` component(s). Defaults to a lock icon. -->
      <slot name="icons">
        <BaseIconInput label="Secure">
          <IconLock />
        </BaseIconInput>
      </slot>
    </template>
  </BaseInput>
</template>
